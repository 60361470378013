import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It was sometime in 2016 that I took my first real headfirst dive into the open
source ecosystem and community. After some years of traditional MVC development
using frameworks like `}<inlineCode parentName="p">{`backbone`}</inlineCode>{` and `}<inlineCode parentName="p">{`angular`}</inlineCode>{` with build tools like `}<inlineCode parentName="p">{`grunt`}</inlineCode>{`
and `}<inlineCode parentName="p">{`gulp`}</inlineCode>{`, I finally jumped ship to `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` and `}<inlineCode parentName="p">{`react`}</inlineCode>{`.`}</p>
    <p>{`Bundling via `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` stood out as it provided something most backend
developers take for granted, a proper `}<em parentName="p">{`module system`}</em>{`. While it wasn't the
first project to interpret CommonJS and ES6 module syntax, it was the first to
start allowing the treatment of `}<em parentName="p">{`all web assets`}</em>{` as `}<inlineCode parentName="p">{`import`}</inlineCode>{`-able modules. This
plus some advanced module resolution features had me sold.`}</p>
    <p>{`So I was building `}<inlineCode parentName="p">{`react`}</inlineCode>{` applications, bundling them with `}<inlineCode parentName="p">{`webpack`}</inlineCode>{`, and
generally loving life. I had also switched to `}<inlineCode parentName="p">{`npm`}</inlineCode>{` for dependency management
at a time when many were still manually including libraries or using other more
browser-focused package managers like `}<inlineCode parentName="p">{`bower`}</inlineCode>{`. As I was doing all this, I kept
seeing a similar theme in many discussions around `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` and those learning
it.`}</p>
    <p>{`There was a general consensus that while `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` was a great tool, it
required a lot of configuration know-how to set up. Comments like these
demonstrate the more extreme sentiments (some are paraphrased to fit)...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reddit.com/r/webdev/comments/6i2yow/i_passionately_hate_webpack/"
        }}>{`I passionately hate webpack...`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/webpack/docs/issues/119"
        }}>{`Whoever is in charge of the docs should be kicked off the project.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/webpack/webpack.js.org/issues/1300#issuecomment-318337358"
        }}>{`It fathoms me that such a large project has the absolute worst docs.`}</a></li>
    </ul>
    <p>{`While not every comment was this harsh, it was somewhat mind-blowing to me how
much people could complain about a `}<em parentName="p">{`free piece of software`}</em>{`. If you're not an
open-source contributor, understand that open source projects -- especially
bigger ones -- require just as much work as professional projects. In some
cases, they require even more work because they get scrutinized by a broader
audience. That's actually a good thing, assuming the maintainer is open to
feedback, but my point is just that you shouldn't be an `}<em parentName="p">{`asshole`}</em>{` about it.
Definitely provide feedback, but go into with some understanding and respect
for the work that's been done and that most if not all of that work has been
done for free.`}</p>
    <p>{`All that said, I did agree that some of the documentation was confusing and I
wanted to help do something about it. I familiarized myself with more of
`}<inlineCode parentName="p">{`webpack`}</inlineCode>{` through both the documentation and source code, and then started
some designs for a new version of the site. Once complete, I shared that work
via `}<a parentName="p" {...{
        "href": "https://github.com/webpack/docs/issues/64#issuecomment-223466819"
      }}>{`a comment`}</a>{` on an issue in the docs repository.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7a22af8d379cfe591ca5ad36856afa25/644c5/webpack-comment.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHvLbMUmh//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIDEhD/2gAIAQEAAQUCZXLfaI5R/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAABABESH/2gAIAQEABj8CMc0v/8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQMUH/2gAIAQEAAT8hxBC8U5AP01//2gAMAwEAAgADAAAAEIMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQACAwEAAAAAAAAAAAAAAREAECExQWH/2gAIAQEAAT8QKuS/cWyijz7ruxYFKtmv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "webpack comment",
            "title": "webpack comment",
            "src": "/static/7a22af8d379cfe591ca5ad36856afa25/644c5/webpack-comment.jpg",
            "srcSet": ["/static/7a22af8d379cfe591ca5ad36856afa25/158ba/webpack-comment.jpg 360w", "/static/7a22af8d379cfe591ca5ad36856afa25/80e3c/webpack-comment.jpg 720w", "/static/7a22af8d379cfe591ca5ad36856afa25/644c5/webpack-comment.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I anxiously awaited the first response, waiting to get shot down as a newb that
had no idea what he was doing. `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` was already a pretty big project at
this point and I figured I was way out of my league. To my surprise, the team
seemed to really like the idea of a new site and that one comment spawned more
discussion and some new threads. Within a few weeks, we started building the
`}<a parentName="p" {...{
        "href": "https://github.com/webpack/webpack.js.org"
      }}>{`new version`}</a>{` of the site.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/893a9bea30b0e12016e5857f4829c731/644c5/webpack-landing.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAUGAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAVu0XK5SlcT/AP/EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREBIT/9oACAEBAAEFAhaxO7Q7OKQ//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIBBQAAAAAAAAAAAAAAAAARARAhMUGR/9oACAEBAAY/Ai1ISNcMH//EABoQAAICAwAAAAAAAAAAAAAAAAAhAWEQMZH/2gAIAQEAAT8hJI2niVdqJQiocP/aAAwDAQACAAMAAAAQV8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCD/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Qqv/EABwQAAICAgMAAAAAAAAAAAAAAAERADEhQVGh8f/aAAgBAQABPxAEIs9wkuRMle0tRjkQEmzbE0oCdgA0ngp//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "webpack landing",
            "title": "webpack landing",
            "src": "/static/893a9bea30b0e12016e5857f4829c731/644c5/webpack-landing.jpg",
            "srcSet": ["/static/893a9bea30b0e12016e5857f4829c731/158ba/webpack-landing.jpg 360w", "/static/893a9bea30b0e12016e5857f4829c731/80e3c/webpack-landing.jpg 720w", "/static/893a9bea30b0e12016e5857f4829c731/644c5/webpack-landing.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Soon it was launched and even more feedback and ideas started flooding in. Most
of what we heard was positive but here and there we still got the occasional
douchey comment. The community overall was wildly supportive and we couldn't
have made the site what it was without their contributions (via PRs and ideas
shared in comments). I learned a lot from the core team and others not just
about `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` but also about managing projects and communicating with others.`}</p>
    <p>{`After about two years, I handed the project off to some new contributors...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      